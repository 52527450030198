.overlayContent {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    margin-top: -75px;
    margin-left: -75px;
    border-radius: 16px;
    .loading-text{
        color: #fff;
        margin-top: .4rem;
    }
}